import React from "react";

import {
  Box,
  Flex,
  Divider,
  PrimaryButton,
  SecondaryButton,
  TextInput,
  Link,
} from "flicket-ui";
import { useRouter } from "next/router";
import { SlateRenderer } from "~components";
import { handlePromise } from "~lib/helpers";

import { Row } from "../events.Row";
import { useOrganization } from "~hooks";
import { sdk } from "~lib";
import { EventQuery, ExtendedFile } from "~graphql/sdk";
import Image from "~components/common/Image";
import useDescriptionText from "~hooks/useDescriptionText";

type MobileDetailProps = EventQuery["event"] & {
  navigate: (cartRecovery?: boolean) => void;
  showSeatsCTA: boolean;
  setIsOpen: (isOpen: boolean) => void;
  bannerImage: ExtendedFile;
};

export const Mobile = ({
  id,
  title,
  description,
  startDate,
  endDate,
  venue,
  termsAndConditions,
  navigate,
  bannerImage,
  showSeatsCTA,
  setIsOpen,
  accessibility,
  descriptionImage,
  enableReleaseCodes,
  isWaitlistAvailable,
}: MobileDetailProps) => {
  const router = useRouter();
  const { organization } = useOrganization();
  const isUnlistedRelease = router.query.slug ? true : false;
  const cartRecovery = !!router.query.cartRecovery;
  const descriptionText = useDescriptionText(description);

  const gotToRelease = async () => {
    const inputElement = document.getElementById(
      "reservationInputM"
    ) as HTMLInputElement;

    const code = inputElement.value.trim();
    if (!code) {
      return;
    }

    const { data: release } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).validateReleasePasswordOrRelease({
        eventId: id,
        password: code,
      })
    );

    void router.push({
      pathname: `/${id}/releases/${encodeURIComponent(
        release?.validateReleasePasswordOrRelease.slug
      )}`,
      query: {
        code: release?.validateReleasePasswordOrRelease?.password
          ? undefined
          : code,
        ...(cartRecovery ? { cartRecovery: "true" } : {}),
      },
    });
  };

  return (
    <Flex
      flexDir="column"
      px={2}
      pb={5}
      maxH="calc(100vh - 73px)"
      overflowY="auto"
      flex="1"
    >
      <Box flex="1">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <Row
          bg="white"
          title={title}
          startDate={startDate}
          endDate={endDate}
          venue={venue}
          isNonFunctional
        />
        {showSeatsCTA ? (
          <>
            <PrimaryButton
              my={3}
              boxShadow="button"
              onClick={() => navigate(cartRecovery)}
              minH="48px"
            >
              Get Tickets
            </PrimaryButton>
          </>
        ) : (
          !isUnlistedRelease &&
          isWaitlistAvailable && (
            <>
              <Link to={`/events/${id}/waitlist`}>
                <PrimaryButton my={3} boxShadow="button" minH="48px">
                  Register for presale
                </PrimaryButton>
              </Link>
            </>
          )
        )}
        {!isUnlistedRelease && enableReleaseCodes && (
          <>
            <TextInput
              name="reservationInputM"
              id="reservationInputM"
              minH="48px"
              placeholder={
                organization?.id === "58106ce0-e1cd-47cb-9c59-0d0192ca6df8"
                  ? "FOR THE LOVE OF THE CLUB CODE"
                  : organization?.id === "45339f71-c0bb-41a9-b642-7d30f1321a84"
                  ? "Club/School/Sponsor Code"
                  : "Release Code"
              }
            />

            <Flex mt={bannerImage ? 5 : 1} justifyContent="center">
              <PrimaryButton
                fontSize={4}
                minH="48px"
                boxShadow="button"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={gotToRelease}
              >
                {organization?.id === "58106ce0-e1cd-47cb-9c59-0d0192ca6df8"
                  ? "FOR THE LOVE OF THE CLUB"
                  : organization?.id === "45339f71-c0bb-41a9-b642-7d30f1321a84"
                  ? "Club/School/Sponsor Code"
                  : "Unlock Allocated Tickets"}
              </PrimaryButton>
            </Flex>
            <Divider />
          </>
        )}
        {bannerImage && (
          <Image alt={title} mt={3} image={bannerImage} borderRadius="md" />
        )}
        <SlateRenderer mb={3} mt={2} value={description} />
        {descriptionImage ? (
          <Image
            alt={descriptionText}
            image={descriptionImage}
            mx={"auto" as any}
          />
        ) : null}
      </Box>
      {showSeatsCTA ? (
        <>
          <PrimaryButton
            my={3}
            boxShadow="button"
            onClick={() => navigate(cartRecovery)}
            minH="48px"
          >
            Get Tickets
          </PrimaryButton>
        </>
      ) : (
        !isUnlistedRelease &&
        isWaitlistAvailable && (
          <>
            <Link to={`/events/${id}/waitlist`}>
              <PrimaryButton my={3} boxShadow="button" minH="48px">
                Register for presale
              </PrimaryButton>
            </Link>
          </>
        )
      )}
      {accessibility && (
        <Flex
          w="100%"
          flexDir="column"
          alignSelf="flex-end"
          mb={{ _: 2, md: 0 }}
          mr={{ _: 0, md: 2 }}
          minH="75px"
        >
          <Divider />
          <SecondaryButton onClick={() => setIsOpen(true)} mt={3} minH="48px">
            Accessibility Info
          </SecondaryButton>
        </Flex>
      )}
      {termsAndConditions && (
        <SecondaryButton
          to={`/events/${id}/terms-and-conditions`}
          minH="48px"
          display="flex"
          w={{ _: "100%", md: "auto" }}
        >
          Terms &amp; Conditions
        </SecondaryButton>
      )}
    </Flex>
  );
};
